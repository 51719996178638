import "./styles.css";
import "./game";
import React from "react";
import ReactDOM from "react-dom";
import { AvatarEditorContainer } from "./react-components/AvatarEditorContainer";
import { dispatch } from "./dispatch";
import constants from "./constants";

// Used externally by the generate-thumbnails script
window.renderThumbnail = (category, part) => {
  dispatch(constants.renderThumbnail, { thumbnailConfig: { category, part } });
};

// 一键免登录
const qs = new URLSearchParams(location.search);
const access_token = qs.get("token");
if (access_token) {
  window.localStorage.setItem(
    "currentUser",
    JSON.stringify({
      access_token,
    })
  );
  window.location.href = "/";
}

let currentUser = window.localStorage.getItem("currentUser");
if (currentUser) {
  currentUser = JSON.parse(currentUser);
}
if (!currentUser?.access_token) {
  // window.location.href = "https://mech.world/?ref=avatar";
}

ReactDOM.render(<AvatarEditorContainer />, document.getElementById("root"));
