import React from "react";

import { Thumbnail } from "./Thumbnail";
import { Chevron } from "./Chevron";

export function CategoryHeading({ categoryName, onClick, isExpanded, selectedPartName, image }) {
  const categoryNames = {
      Hair: "发型",
      Head: "头部",
      Eyes: "眼睛",
      Eyebrows: "眉毛",
      Mouth: "嘴巴",
      "Facial Hair": "胡子",
      Hands: "手",
      Torso: "身体",
      "Torso Jacket": "穿着",
      Headwear: "头饰",
      Eyewear: "眼镜",
      Earring: "耳环",
      Accessory: "配饰",
    };
  return (
    <div className="categoryHeading" onClick={onClick}>
      <h2 className="categoryName">{categoryNames[categoryName] ? categoryNames[categoryName] : categoryName}</h2>
      <Chevron {...{ isExpanded }} />
      {/* <h2 className="selectedPartName">{selectedPartName}</h2> */}
      <Thumbnail image={image} />
    </div>
  );
}
