import React from "react";
import { Toolbar } from "./Toolbar";
import { UploadButton } from "./UploadButton";
import { MoreMenu } from "./MoreMenu";
import { dispatch } from "../dispatch";
import constants from "../constants";

function dispatchResetView() {
  dispatch(constants.resetView);
}

function dispatchExportAvatar() {
  dispatch(constants.exportAvatar);
}

export function ToolbarContainer({ onGLBUploaded, randomizeConfig }) {
  return (
    <Toolbar>
      <div className="toolbarContent">
        <span className="appName">
            <img src="https://cdn.portal.mechas.art/imgs/logo-black.png" />
        </span>
        <button onClick={randomizeConfig}>随机生成</button>
        <button onClick={dispatchResetView}>重置相机</button>
        <button onClick={dispatchExportAvatar} className="primary">
          保存头像
        </button>
      </div>
    </Toolbar>
  );
}
