export default {
  "Head": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": true
      },
      {
        "value": "head_alien-skin-1",
        "displayName": "Alien Skin 1",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-1"
        }
      },
      {
        "value": "head_alien-skin-2",
        "displayName": "Alien Skin 2",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-2"
        }
      },
      {
        "value": "head_alien-skin-3",
        "displayName": "Alien Skin 3",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-3"
        }
      },
      {
        "value": "head_alien-skin-4",
        "displayName": "Alien Skin 4",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-4"
        }
      },
      {
        "value": "head_alien-skin-5",
        "displayName": "Alien Skin 5",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-5"
        }
      },
      {
        "value": "head_alien-skin-6",
        "displayName": "Alien Skin 6",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-6"
        }
      },
      {
        "value": "head_alien-skin-7",
        "displayName": "Alien Skin 7",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-7"
        }
      },
      {
        "value": "head_alien-skin-8",
        "displayName": "Alien Skin 8",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-8"
        }
      },
      {
        "value": "head_alien-skin-a",
        "displayName": "Alien Skin A",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-a"
        }
      },
      {
        "value": "head_alien-skin-b",
        "displayName": "Alien Skin B",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-b"
        }
      },
      {
        "value": "head_alien-skin-c",
        "displayName": "Alien Skin C",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-c"
        }
      },
      {
        "value": "head_alien-skin-d",
        "displayName": "Alien Skin D",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-d"
        }
      },
      {
        "value": "head_alien-skin-e",
        "displayName": "Alien Skin E",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-e"
        }
      },
      {
        "value": "head_alien-skin-f",
        "displayName": "Alien Skin F",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-f"
        }
      },
      {
        "value": "head_alien-skin-h",
        "displayName": "Alien Skin H",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-h"
        }
      },
      {
        "value": "head_alien-skin-i",
        "displayName": "Alien Skin I",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-i"
        }
      },
      {
        "value": "head_alien-skin-j",
        "displayName": "Alien Skin J",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-j"
        }
      },
      {
        "value": "head_alien-skin-k",
        "displayName": "Alien Skin K",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-k"
        }
      }
    ],
    "description": {
      "Type": {
        "options": [
          null,
          "alien"
        ],
        "isPrimaryOption": true
      },
      "Skin Tone": {
        "options": [
          "skin-1",
          "skin-2",
          "skin-3",
          "skin-4",
          "skin-5",
          "skin-6",
          "skin-7",
          "skin-8",
          "skin-a",
          "skin-b",
          "skin-c",
          "skin-d",
          "skin-e",
          "skin-f",
          "skin-h",
          "skin-i",
          "skin-j",
          "skin-k"
        ]
      }
    }
  },
  "Eyes": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": true
      },
      {
        "value": "eyes_style-1-black",
        "displayName": "Style 1 Black",
        "description": {
          "Style": "style-1",
          "Color": "black"
        }
      },
      {
        "value": "eyes_style-2-black",
        "displayName": "Style 2 Black",
        "description": {
          "Style": "style-2",
          "Color": "black"
        }
      },
      {
        "value": "eyes_style-3-blue",
        "displayName": "Style 3 Blue",
        "description": {
          "Style": "style-3",
          "Color": "blue"
        }
      },
      {
        "value": "eyes_style-4-red",
        "displayName": "Style 4 Red",
        "description": {
          "Style": "style-4",
          "Color": "red"
        }
      },
      {
        "value": "eyes_style-5-red",
        "displayName": "Style 5 Red",
        "description": {
          "Style": "style-5",
          "Color": "red"
        }
      },
      {
        "value": "eyes_style-6-mixed",
        "displayName": "Style 6 Mixed",
        "description": {
          "Style": "style-6",
          "Color": "mixed"
        }
      },
      {
        "value": "eyes_style-7-white",
        "displayName": "Style 7 White",
        "description": {
          "Style": "style-7",
          "Color": "white"
        }
      },
      {
        "value": "eyes_style-8-black",
        "displayName": "Style 8 Black",
        "description": {
          "Style": "style-8",
          "Color": "black"
        }
      },
      {
        "value": "eyes_style-9-red",
        "displayName": "Style 9 Red",
        "description": {
          "Style": "style-9",
          "Color": "red"
        }
      },
      {
        "value": "eyes_style-a-black",
        "displayName": "Style A Black",
        "description": {
          "Style": "style-a",
          "Color": "black"
        }
      },
      {
        "value": "eyes_style-b-black",
        "displayName": "Style B Black",
        "description": {
          "Style": "style-b",
          "Color": "black"
        }
      },
      {
        "value": "eyes_style-c-black",
        "displayName": "Style C Black",
        "description": {
          "Style": "style-c",
          "Color": "black"
        }
      },
      {
        "value": "eyes_style-d-black",
        "displayName": "Style D Black",
        "description": {
          "Style": "style-d",
          "Color": "black"
        }
      },
      {
        "value": "eyes_style-e-white",
        "displayName": "Style E White",
        "description": {
          "Style": "style-e",
          "Color": "white"
        }
      },
      {
        "value": "eyes_style-f-red",
        "displayName": "Style F Red",
        "description": {
          "Style": "style-f",
          "Color": "red"
        }
      }
    ],
    "description": {
      "Style": {
        "options": [
          null,
          "style-1",
          "style-2",
          "style-3",
          "style-4",
          "style-5",
          "style-6",
          "style-7",
          "style-8",
          "style-9",
          "style-a",
          "style-b",
          "style-c",
          "style-d",
          "style-e",
          "style-f"
        ],
        "isPrimaryOption": true
      },
      "Color": {
        "options": [
          "black",
          "blue",
          "red",
          "mixed",
          "white"
        ]
      }
    },
    "bisectInThumbnail": true
  },
  "Eyebrows": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": true
      },
      {
        "value": "eyebrows_style-1-black",
        "displayName": "Style 1 Black",
        "description": {
          "Style": "style-1",
          "Color": "black"
        }
      },
      {
        "value": "eyebrows_style-2-black",
        "displayName": "Style 2 Black",
        "description": {
          "Style": "style-2",
          "Color": "black"
        }
      },
      {
        "value": "eyebrows_style-3-black",
        "displayName": "Style 3 Black",
        "description": {
          "Style": "style-3",
          "Color": "black"
        }
      }
    ],
    "description": {
      "Style": {
        "options": [
          null,
          "style-1",
          "style-2",
          "style-3"
        ],
        "isPrimaryOption": true
      },
      "Color": {
        "options": [
          "black"
        ]
      }
    },
    "bisectInThumbnail": true
  },
  "Mouth": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": true
      },
      {
        "value": "mouth_alien-skin-1",
        "displayName": "Alien Skin 1",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-1"
        }
      },
      {
        "value": "mouth_alien-skin-2",
        "displayName": "Alien Skin 2",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-2"
        }
      },
      {
        "value": "mouth_alien-skin-3",
        "displayName": "Alien Skin 3",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-3"
        }
      },
      {
        "value": "mouth_alien-skin-4",
        "displayName": "Alien Skin 4",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-4"
        }
      },
      {
        "value": "mouth_alien-skin-5",
        "displayName": "Alien Skin 5",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-5"
        }
      },
      {
        "value": "mouth_alien-skin-6",
        "displayName": "Alien Skin 6",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-6"
        }
      },
      {
        "value": "mouth_alien-skin-7",
        "displayName": "Alien Skin 7",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-7"
        }
      },
      {
        "value": "mouth_alien-skin-8",
        "displayName": "Alien Skin 8",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-8"
        }
      },
      {
        "value": "mouth_alien-skin-9",
        "displayName": "Alien Skin 9",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-9"
        }
      },
      {
        "value": "mouth_alien-skin-a",
        "displayName": "Alien Skin A",
        "description": {
          "Type": "alien",
          "Skin Tone": "skin-a"
        }
      }
    ],
    "description": {
      "Type": {
        "options": [
          null,
          "alien"
        ],
        "isPrimaryOption": true
      },
      "Skin Tone": {
        "options": [
          "skin-1",
          "skin-2",
          "skin-3",
          "skin-4",
          "skin-5",
          "skin-6",
          "skin-7",
          "skin-8",
          "skin-9",
          "skin-a"
        ]
      }
    }
  },
  "Hands": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": true
      },
      {
        "value": "hands_skin-1",
        "displayName": "Skin 1"
      },
      {
        "value": "hands_skin-2",
        "displayName": "Skin 2"
      },
      {
        "value": "hands_skin-3",
        "displayName": "Skin 3"
      },
      {
        "value": "hands_skin-4",
        "displayName": "Skin 4"
      },
      {
        "value": "hands_skin-5",
        "displayName": "Skin 5"
      },
      {
        "value": "hands_skin-6",
        "displayName": "Skin 6"
      },
      {
        "value": "hands_skin-7",
        "displayName": "Skin 7"
      },
      {
        "value": "hands_skin-8",
        "displayName": "Skin 8"
      },
      {
        "value": "hands_skin-a",
        "displayName": "Skin A"
      },
      {
        "value": "hands_skin-b",
        "displayName": "Skin B"
      },
      {
        "value": "hands_skin-c",
        "displayName": "Skin C"
      },
      {
        "value": "hands_skin-d",
        "displayName": "Skin D"
      },
      {
        "value": "hands_skin-e",
        "displayName": "Skin E"
      },
      {
        "value": "hands_skin-f",
        "displayName": "Skin F"
      },
      {
        "value": "hands_skin-g",
        "displayName": "Skin G"
      },
      {
        "value": "hands_skin-h",
        "displayName": "Skin H"
      }
    ],
    "bisectInThumbnail": true
  },
  "Torso": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": true
      },
      {
        "value": "torso_style-1-alien-1",
        "displayName": "Style 1 Alien 1",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-1"
        }
      },
      {
        "value": "torso_style-1-alien-2",
        "displayName": "Style 1 Alien 2",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-2"
        }
      },
      {
        "value": "torso_style-1-alien-3",
        "displayName": "Style 1 Alien 3",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-3"
        }
      },
      {
        "value": "torso_style-1-alien-4",
        "displayName": "Style 1 Alien 4",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-4"
        }
      },
      {
        "value": "torso_style-1-alien-5",
        "displayName": "Style 1 Alien 5",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-5"
        }
      },
      {
        "value": "torso_style-1-alien-6",
        "displayName": "Style 1 Alien 6",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-6"
        }
      },
      {
        "value": "torso_style-1-alien-7",
        "displayName": "Style 1 Alien 7",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-7"
        }
      },
      {
        "value": "torso_style-1-alien-8",
        "displayName": "Style 1 Alien 8",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-8"
        }
      },
      {
        "value": "torso_style-1-alien-9",
        "displayName": "Style 1 Alien 9",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-9"
        }
      },
      {
        "value": "torso_style-1-alien-a",
        "displayName": "Style 1 Alien A",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-a"
        }
      },
      {
        "value": "torso_style-1-alien-b",
        "displayName": "Style 1 Alien B",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-b"
        }
      },
      {
        "value": "torso_style-1-alien-c",
        "displayName": "Style 1 Alien C",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-c"
        }
      },
      {
        "value": "torso_style-1-alien-d",
        "displayName": "Style 1 Alien D",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-d"
        }
      },
      {
        "value": "torso_style-1-alien-e",
        "displayName": "Style 1 Alien E",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-e"
        }
      },
      {
        "value": "torso_style-1-alien-f",
        "displayName": "Style 1 Alien F",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-f"
        }
      },
      {
        "value": "torso_style-1-alien-g",
        "displayName": "Style 1 Alien G",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-g"
        }
      },
      {
        "value": "torso_style-1-alien-h",
        "displayName": "Style 1 Alien H",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-h"
        }
      },
      {
        "value": "torso_style-1-alien-i",
        "displayName": "Style 1 Alien I",
        "description": {
          "Type": "style-1",
          "Outfit": "alien-i"
        }
      }
    ],
    "description": {
      "Type": {
        "options": [
          null,
          "style-1"
        ],
        "isPrimaryOption": true
      },
      "Outfit": {
        "options": [
          "alien-1",
          "alien-2",
          "alien-3",
          "alien-4",
          "alien-5",
          "alien-6",
          "alien-7",
          "alien-8",
          "alien-9",
          "alien-a",
          "alien-b",
          "alien-c",
          "alien-d",
          "alien-e",
          "alien-f",
          "alien-g",
          "alien-h",
          "alien-i"
        ]
      }
    }
  },
  "Torso Jacket": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": false,
        "randomizationWeight": 16
      },
      {
        "value": "torso-jacket_style-1-hoodie-1-blue",
        "displayName": "Style 1 Hoodie 1 Blue",
        "description": {
          "Type": "style-1",
          "Outfit": "hoodie-1-blue"
        }
      },
      {
        "value": "torso-jacket_style-1-hoodie-1-brown",
        "displayName": "Style 1 Hoodie 1 Brown",
        "description": {
          "Type": "style-1",
          "Outfit": "hoodie-1-brown"
        }
      },
      {
        "value": "torso-jacket_style-1-hoodie-1-mixed",
        "displayName": "Style 1 Hoodie 1 Mixed",
        "description": {
          "Type": "style-1",
          "Outfit": "hoodie-1-mixed"
        }
      },
      {
        "value": "torso-jacket_style-1-overcoat-1-red",
        "displayName": "Style 1 Overcoat 1 Red",
        "description": {
          "Type": "style-1",
          "Outfit": "overcoat-1-red"
        }
      },
      {
        "value": "torso-jacket_style-1-suit-1-red",
        "displayName": "Style 1 Suit 1 Red",
        "description": {
          "Type": "style-1",
          "Outfit": "suit-1-red"
        }
      },
      {
        "value": "torso-jacket_style-1-suit-1-white",
        "displayName": "Style 1 Suit 1 White",
        "description": {
          "Type": "style-1",
          "Outfit": "suit-1-white"
        }
      },
      {
        "value": "torso-jacket_style-1-suit-1-yellow",
        "displayName": "Style 1 Suit 1 Yellow",
        "description": {
          "Type": "style-1",
          "Outfit": "suit-1-yellow"
        }
      },
      {
        "value": "torso-jacket_style-1-tshirt-1-blue",
        "displayName": "Style 1 Tshirt 1 Blue",
        "description": {
          "Type": "style-1",
          "Outfit": "tshirt-1-blue"
        }
      },
      {
        "value": "torso-jacket_style-1-tshirt-1-gray",
        "displayName": "Style 1 Tshirt 1 Gray",
        "description": {
          "Type": "style-1",
          "Outfit": "tshirt-1-gray"
        }
      },
      {
        "value": "torso-jacket_style-1-tshirt-1-green",
        "displayName": "Style 1 Tshirt 1 Green",
        "description": {
          "Type": "style-1",
          "Outfit": "tshirt-1-green"
        }
      },
      {
        "value": "torso-jacket_style-1-tshirt-1-mixed",
        "displayName": "Style 1 Tshirt 1 Mixed",
        "description": {
          "Type": "style-1",
          "Outfit": "tshirt-1-mixed"
        }
      },
      {
        "value": "torso-jacket_style-1-tshirt-1-red",
        "displayName": "Style 1 Tshirt 1 Red",
        "description": {
          "Type": "style-1",
          "Outfit": "tshirt-1-red"
        }
      },
      {
        "value": "torso-jacket_style-1-tshirt-1-white",
        "displayName": "Style 1 Tshirt 1 White",
        "description": {
          "Type": "style-1",
          "Outfit": "tshirt-1-white"
        }
      },
      {
        "value": "torso-jacket_style-1-tshirt-2-mixed",
        "displayName": "Style 1 Tshirt 2 Mixed",
        "description": {
          "Type": "style-1",
          "Outfit": "tshirt-2-mixed"
        }
      },
      {
        "value": "torso-jacket_style-1-vest-1-black",
        "displayName": "Style 1 Vest 1 Black",
        "description": {
          "Type": "style-1",
          "Outfit": "vest-1-black"
        }
      }
    ],
    "description": {
      "Type": {
        "options": [
          null,
          "style-1"
        ],
        "isPrimaryOption": true
      },
      "Outfit": {
        "options": [
          "hoodie-1-blue",
          "hoodie-1-brown",
          "hoodie-1-mixed",
          "overcoat-1-red",
          "suit-1-red",
          "suit-1-white",
          "suit-1-yellow",
          "tshirt-1-blue",
          "tshirt-1-gray",
          "tshirt-1-green",
          "tshirt-1-mixed",
          "tshirt-1-red",
          "tshirt-1-white",
          "tshirt-2-mixed",
          "vest-1-black"
        ]
      }
    },
    "matchRandomization": {
      "categoryName": "Torso",
      "primaryOption": "Type",
      "secondaryOption": "Outfit"
    }
  },
  "Headwear": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": false,
        "randomizationWeight": 20
      },
      {
        "value": "headwear_bowtie-white",
        "displayName": "Bowtie White",
        "description": {
          "Type": "bowtie",
          "Color": "white"
        }
      },
      {
        "value": "headwear_cowboyhat-brown",
        "displayName": "Cowboyhat Brown",
        "description": {
          "Type": "cowboyhat",
          "Color": "brown"
        }
      },
      {
        "value": "headwear_halo-blonde",
        "displayName": "Halo Blonde",
        "description": {
          "Type": "halo",
          "Color": "blonde"
        }
      },
      {
        "value": "headwear_halo-blue",
        "displayName": "Halo Blue",
        "description": {
          "Type": "halo",
          "Color": "blue"
        }
      },
      {
        "value": "headwear_hat1-brown",
        "displayName": "Hat1 Brown",
        "description": {
          "Type": "hat1",
          "Color": "brown"
        }
      },
      {
        "value": "headwear_hat1-white",
        "displayName": "Hat1 White",
        "description": {
          "Type": "hat1",
          "Color": "white"
        }
      },
      {
        "value": "headwear_hat2-black",
        "displayName": "Hat2 Black",
        "description": {
          "Type": "hat2",
          "Color": "black"
        }
      },
      {
        "value": "headwear_hat2-mixed",
        "displayName": "Hat2 Mixed",
        "description": {
          "Type": "hat2",
          "Color": "mixed"
        }
      },
      {
        "value": "headwear_hat2-white",
        "displayName": "Hat2 White",
        "description": {
          "Type": "hat2",
          "Color": "white"
        }
      },
      {
        "value": "headwear_horn-red",
        "displayName": "Horn Red",
        "description": {
          "Type": "horn",
          "Color": "red"
        }
      },
      {
        "value": "headwear_navycap-mixed",
        "displayName": "Navycap Mixed",
        "description": {
          "Type": "navycap",
          "Color": "mixed"
        }
      },
      {
        "value": "headwear_primitive-dark brown",
        "displayName": "Primitive Dark brown",
        "description": {
          "Type": "primitive",
          "Color": "dark brown"
        }
      },
      {
        "value": "headwear_primitive-green",
        "displayName": "Primitive Green",
        "description": {
          "Type": "primitive",
          "Color": "green"
        }
      },
      {
        "value": "headwear_primitive-red",
        "displayName": "Primitive Red",
        "description": {
          "Type": "primitive",
          "Color": "red"
        }
      },
      {
        "value": "headwear_scarf-blue",
        "displayName": "Scarf Blue",
        "description": {
          "Type": "scarf",
          "Color": "blue"
        }
      },
      {
        "value": "headwear_scarf-brown",
        "displayName": "Scarf Brown",
        "description": {
          "Type": "scarf",
          "Color": "brown"
        }
      },
      {
        "value": "headwear_scarf-light brown",
        "displayName": "Scarf Light brown",
        "description": {
          "Type": "scarf",
          "Color": "light brown"
        }
      }
    ],
    "description": {
      "Type": {
        "options": [
          null,
          "bowtie",
          "cowboyhat",
          "halo",
          "hat1",
          "hat2",
          "horn",
          "navycap",
          "primitive",
          "scarf"
        ],
        "isPrimaryOption": true
      },
      "Color": {
        "options": [
          "white",
          "brown",
          "blonde",
          "blue",
          "black",
          "mixed",
          "red",
          "dark brown",
          "green",
          "light brown"
        ]
      }
    }
  },
  "Eyewear": {
    "parts": [
      {
        "value": null,
        "displayName": "None",
        "excludeFromRandomize": false,
        "randomizationWeight": 20
      },
      {
        "value": "eyewear_alien-1",
        "displayName": "Alien 1"
      },
      {
        "value": "eyewear_alien-2",
        "displayName": "Alien 2"
      },
      {
        "value": "eyewear_alien-3",
        "displayName": "Alien 3"
      },
      {
        "value": "eyewear_alien-4",
        "displayName": "Alien 4"
      },
      {
        "value": "eyewear_alien-5",
        "displayName": "Alien 5"
      },
      {
        "value": "eyewear_alien-6",
        "displayName": "Alien 6"
      },
      {
        "value": "eyewear_alien-7",
        "displayName": "Alien 7"
      },
      {
        "value": "eyewear_alien-8",
        "displayName": "Alien 8"
      },
      {
        "value": "eyewear_alien-9",
        "displayName": "Alien 9"
      }
    ]
  }
};